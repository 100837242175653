<template>
  <a-modal
    title="支付"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="收款公司">
              <span v-decorator="['electric_account_company', {initialValue: model.electric_account_company}]" >{{ model.electric_account_company }}</span>
            </a-form-item>
            <a-form-item label="开户行">
              <span v-decorator="['electric_account_bank', {initialValue: model.electric_account_bank}]" >{{ model.electric_account_bank }}</span>
            </a-form-item>
            <a-form-item label="收款账号">
              <span v-decorator="['electric_account', {initialValue: model.electric_account}]" >{{ model.electric_account }}</span>
            </a-form-item>
            <a-form-item label="付款金额">
              <a-input v-decorator="['amount', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="银行账号">
              <a-input :maxLength="32" v-decorator="['account', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="开户行">
              <a-input :maxLength="32" v-decorator="['bank', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="交易凭证号">
              <a-input :maxLength="64" v-decorator="['number', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>
            <a-form-item label="备注">
              <a-textarea v-decorator="['description', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="上传付款凭证" v-show="false">
              <a-textarea v-decorator="['img', {rules: [{required: true, message: '必填项，请填写信息'}]}]" />
            </a-form-item>
            <a-form-item label="上传付款凭证">
              <div class="clearfix">
                <a-upload
                  v-decorator="[
                    'img_all',
                    {
                      valuePropName: 'img_all',
                      rules: [{required: true, message: '必填项，请填写信息'}]
                    },
                  ]"
                  name="file"
                  accept="image/png, image/jpeg, image/jpg"
                  :action="actionUrl"
                  :data="new_multipart_params"
                  list-type="picture-card"
                  :file-list="fileList"
                  :before-upload="beforeUpload"
                  @preview="handlePreview"
                  @change="handleChange"
                >
                  <div v-if="fileList.length < 1">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">
                      Upload
                    </div>
                  </div>
                </a-upload>
                <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                  <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { oss_token_list } from '@/api/oss_token'
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
// 表单字段
const fields = ['id', 'bill_id', 'corporation_id', 'amount', 'account', 'bank',
  'number', 'img', 'status', 'category', 'corporation']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      new_multipart_params: {
        'key': '',
        'policy': '',
        'OSSAccessKeyId': '',
        'success_action_status': '200',
        'signature': ''
      },
      actionUrl: '',
      previewVisible: false,
      previewImage: '',
      ImgUrl: '',
      fileList: [],
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries
    }
  },
  created () {
    this.fileList = []

    oss_token_list().then(({ data }) => {
      console.log(data, '我是返回值')
      this.new_multipart_params.OSSAccessKeyId = data.accessid
      this.new_multipart_params.policy = data.policy
      this.new_multipart_params.signature = data.signature
      this.actionUrl = 'https://static.jnzbtz.com'
    })
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    // 进口图片
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      console.log('我要预览', file.preview)
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange ({ fileList, file }) {
      this.fileList = fileList
      console.log('我是图片', file)
      if (file.status === 'removed') {
        this.form.setFieldsValue({ img: '' })
        this.$message.error('请重新上传')
      }
      if (file.status === 'done') {
        this.form.setFieldsValue({ img: this.actionUrl + '/' + this.new_multipart_params.key })
      }
    },
    beforeUpload (file) {
      this.new_multipart_params.key = file.uid + '_' + file.name
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('你只能上传jpeg/png格式的图片')
      }
      const isLt5M = file.size / 1024 / 1024 < 5
      return isJpgOrPng && isLt5M
    },
    handleCancel (file) {
      this.previewVisible = false
      console.log(file, '我是删除')
    }
  }
}
</script>
