<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="电表编号">
              <a-select
                option-label-prop="tt"
                :filter-option="false"
                :not-found-content="null"
                show-search
                allow-clear
                v-decorator="[
                  'meter_id',
                  {
                    rules: [
                      { required: true, message: '' },
                    ],
                  },
                ]"
                :default-active-first-option="false"
                @search="meterSearch"
                @change="handleMeterChange"
              >
                <a-select-option v-for="d in metersList" :key="d.id" :tt="d.number">
                  {{ d.number }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="充值金额(元)" >
              <a-input v-decorator="['total_payment', {rules: [{required: true, message: '必填项，请填写信息'}, {max:50, message: '输入超出长度限制'}]}]" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import { electricity_meter_list } from '@/api/electricity'

// 表单字段
const fields = ['id', 'number', 'corporation_id', 'total_payment']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries,
      metersList: []
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  methods: {
    handleMeterChange (value) {
      console.log(value)
    },
    meterSearch (value) {
      electricity_meter_list({ number: value, corporation_id: this.$Dictionaries.corporation_id, is_prepay: true })
        .then(res => {
          console.log(res.data, '我是数据列表@')
          this.metersList = res.data.entries
        })
    },
    displayRender ({ labels }) {
      return labels[labels.length - 1]
    }
  }
}
</script>
