import request from '@/utils/request'

const electricity_billApi = {
  electricity_bill_list: '/electricity/prepay_bill/',
  electricity_bill_create: '/electricity/prepay_bill/',
  electricity_bill_update: '/electricity/prepay_bill/',
  electricity_bill_partial_update: '/electricity/prepay_bill/',
  electricity_bill_delete: '/electricity/prepay_bill/',
  electricity_meter_list: '/electricity_meter/'
}

/**
 * 列表
 */
export function electricity_bill_list (parameter) {
  return request({
    url: electricity_billApi.electricity_bill_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function electricity_bill_create (parameter) {
  return request({
    url: electricity_billApi.electricity_bill_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function electricity_bill_partial_update (parameter, electricity_bill_id) {
  return request({
    url: electricity_billApi.electricity_bill_partial_update + electricity_bill_id + '/',
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

/**
 * 删除
 */
export function electricity_bill_delete (electricity_bill_id) {
  return request({
    url: electricity_billApi.electricity_bill_delete + electricity_bill_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 列表
 */
export function electricity_meter_list (parameter) {
  return request({
    url: electricity_billApi.electricity_meter_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
